



























import { Component, Vue } from "vue-property-decorator";
import errGif from "@/assets/images/401.gif";

@Component({
  name: "Page401",
})
export default class extends Vue {
  errGif = errGif + "?" + +new Date();
  ewizardClap =
    "https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646";
  dialogVisible = false;

  back() {
    if (!this.$route.query.noGoBack) {
      this.$router.go(-2);
    }
  }
}
